export const IS_DEVELOPMENT =
    !process.env.REACT_APP_IS_DEVELOPMENT ||
    process.env.REACT_APP_IS_DEVELOPMENT === "true";

const domainFromURL = window.location.protocol + "//" + window.location.hostname.replace(/admin-|admin\./, "");

export const domain = process.env.REACT_APP_DOMAIN_API ? 'https://' + process.env.REACT_APP_DOMAIN_API : domainFromURL;

export const api = domain + "/api";
export const captchaSiteKey = "6LdlikYqAAAAANA-Ha-7QHh2NKz5T_YNpT98QKR5";
